@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');

body{
    margin: 0;
    padding:0;
    font-family: "Open Sans", sans-serif;
}

@tailwind base;
@tailwind components;
@tailwind utilities;


.e-grid .e-gridheader th:nth-child(2),
.e-grid .e-gridcontent .e-rowcell:nth-child(2) {
  position: sticky;
  left: 0;
  background-color: white; /* Adjust the background color as needed */
  z-index: 40;
}